import $ from 'jquery';

if ($('.heroblock').length && $('.video-wrapper').length && $('.video-wrapper').is(':visible')) {

    var tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/player_api';
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    var vid = [], tv, currVid = 0, playerDefaults = { autoplay: 0, autohide: 1, modestbranding: 1, rel: 0, showinfo: 0, controls: 0, disablekb: 1, enablejsapi: 0, iv_load_policy: 3 };

    $.each($('.video-wrapper').data("youtubeids").split(','), function () {
        vid.push({ 'videoId': $.trim(this), 'suggestedQuality': 'hd720' });
    });

    window.onPlayerReady = function () {
        tv.loadVideoById(vid[currVid]);
        tv.mute();
    };

    window.onPlayerStateChange = function (e) {
        if (e.data === 1) {
            $('#herotv').addClass('active');
        } else if (e.data === 2) {
            $('#herotv').removeClass('active');
            if (currVid === vid.length - 1) {
                currVid = 0;
            } else {
                currVid++;
            }
            tv.loadVideoById(vid[currVid]);
        }
    };

    window.onYouTubePlayerAPIReady = function () {
        tv = new YT.Player('herotv', { events: { 'onReady': window.onPlayerReady, 'onStateChange': window.onPlayerStateChange }, playerVars: playerDefaults });
    };

    $(window).on('load resize', function () {

        var w = $(window).width() + 200, h = $(window).height() + 200, screen = $('.video-wrapper > div');

        if (w / h > 16 / 9) {
            tv.setSize(w, w / 16 * 9);
            screen.css({ 'left': '0' });
        } else {
            tv.setSize(h / 9 * 16, h);
            screen.css({ 'left': -(screen.outerWidth() - w) / 2 });
        }
    });

}