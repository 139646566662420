import $ from 'jquery';
import 'url-search-params-polyfill';

var pageLang = () => $('html')[0].lang;


$(document).ready(function () {

    //get correct url
    var baseUrl = window.location.origin;
    var searchPageName = "haku";

    if (baseUrl.includes("redcross")) {
        searchPageName = "search";
    }
    else if (baseUrl.includes("rodakorset")) {
        searchPageName = "sok";
    }
    else {
        var lang = pageLang();
        if (lang == "sv") {
            searchPageName = "sv/sok";
        }
        else if (lang == "en") {
            searchPageName = "en/search";
        }
        else {
            searchPageName = "haku";
        }
    }

    //autofocus to input field
    $('#dtnavbarSearch').click(function (e) {
        e.preventDefault();
        var ip = document.getElementById("dtsearch-box");
        setTimeout(function () {
            ip.focus()
        }, 500);
    })
    $('#mbnavbarSearch').click(function (e) {
        e.preventDefault();
        var ip = document.getElementById("mbsearch-box");
        setTimeout(function () {
            ip.focus()
        }, 500);
    })


    //go to last scroll location when getting more results
    if ($('body.searchpage').length > 0) {
        
        if (sessionStorage.scrollTop != "undefined") {
            $(window).scrollTop(sessionStorage.scrollTop);
        }
        $(window).scroll(function () {
            sessionStorage.scrollTop = $(this).scrollTop() - 350;
        });
    }

    //click search icon to go to search page
    $('.searchItem').click(function () { 
        var query = $(this).siblings('.search-box').val(); // get the textbox value
        var url = baseUrl + '/' + searchPageName + '/?q=' + query;
        location.href = url; // redirect
        sessionStorage.scrollTop = 0;
        return false; // cancel default redirect
    });

    //press enter on search box
    $('.search-box').keyup(function (event) {
        if (event.keyCode == 13) {
            var query = $(this).val(); 
            var url = baseUrl + '/' + searchPageName + '/?q=' + query;
            location.href = url; // redirect
            sessionStorage.scrollTop = 0;
        }
    });


     //search content while typing to desktop search bar
    var typingTimer;

    $('#dtsearch-box').keyup(function () {
        
        clearTimeout(typingTimer);
        var query = $(this).val();
        var url = baseUrl + '/' + searchPageName + '/?q=' + query + '&preview=true';

        typingTimer = setTimeout(function () {
            $.get(url, function (data) {
                $('.PreSearchResult').html(data);
                $('#dtsearch-box').attr('autocomplete', 'off');
            })
        }, 1000);
    });

    $('#dtsearch-box').keydown(function () {
        clearTimeout(typingTimer);
    });


     //clear old previewcontent when view is closed
    $(document).on("click", function (event) {
        if (!$(event.target).closest(".searchContent").length) {
            $('.PreSearchResult').empty();
            $('.preSearch').val('');
        }
    })  

})