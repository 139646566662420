import $ from 'jquery';

$(document).ready(function () {

    function ValidateTab(currentTab) {
        var valid = true;
        currentTab.find('input[type=number]').each(function () {
            if ($(this).val() == "") {
                valid = false;
                $(this).addClass("is-invalid");
            } else {
                $(this).removeClass("is-invalid");
            }
        });
        if (currentTab.find("input[type=checkbox]").length > 0 && currentTab.find("input[type=checkbox]:checked").length == 0) {
            valid = false;
        }
        return valid;
    }

    function FocusToTop(elem, speed = 400) {
        if ($(".navbar-toggler").is(":visible")) {
            $('html, body').animate({
                scrollTop: elem.offset().top
            }, speed);
        }
    }

    $("form#segmentpoll").each(function () {
        var currentForm = $(this);

        currentForm.find('input[type=checkbox], input[type=number]').on("change input", function () {
            var currentTab = $(this).closest(".tab.active");
            var currentBtn = currentTab.find("button.nextbtn");
            var valid = ValidateTab(currentTab);
            valid ? currentBtn.removeAttr("disabled").button('refresh') : currentBtn.attr("disabled", "disabled").button('refresh');
        });

        currentForm.on("submit", function (event) {
            event.preventDefault();
            var data = currentForm.serialize();
            $.getJSON(currentForm.attr("action") + "?" + data, function (json) {

                currentForm.find(".tab > .segment").addClass("d-none");
                currentForm.find(".tab > .wait").addClass("d-none");
                currentForm.find(".tab > .SEGMENT_" + json.SEGMENT[0]).removeClass("d-none");

                var shareTxt = currentForm.find(".tab > .SEGMENT_" + json.SEGMENT[0] + " p.share").text();

                var fbShareElem = currentForm.find("a.facebookanchor");
                var fbShareUrl = new URL(fbShareElem.attr('href'));
                fbShareUrl.searchParams.set('quote', shareTxt);
                fbShareElem.attr('href', fbShareUrl);

                var twShareElem = currentForm.find("a.twitteranchor");
                var twShareUrl = new URL(twShareElem.attr('href'));
                twShareUrl.searchParams.set('text', shareTxt);
                twShareElem.attr('href', twShareUrl);

                var liShareElem = currentForm.find("a.linkedinanchor");
                var liShareUrl = new URL(liShareElem.attr('href'));
                liShareUrl.searchParams.set('summary', shareTxt);
                liShareElem.attr('href', liShareUrl);

                var saveProfileElem = currentForm.find("a.saveprofilelink");
                var saveProfileUrl = saveProfileElem.attr('href') + "?" + data;
                saveProfileElem.attr('href', saveProfileUrl);

                FocusToTop(currentForm, 0);

            }).fail(function () {
                currentForm.find(".tab > .segment").addClass("d-none");
                currentForm.find(".tab > .wait").addClass("d-none");
                currentForm.find(".tab > .error").removeClass("d-none");
            });
        });

        currentForm.find(".tab").each(function (index) {
            var cssClass = "step" + (index == 0 ? " active" : "");
            currentForm.find(".stepway").append($('<span class="' + cssClass + '"></span>'));
        });

        currentForm.find("button.prevbtn").on("click", function () {
            var currentTab = $(this).closest(".tab");
            var previousTab = currentTab.prev('.tab');
            currentTab.removeClass("active");
            previousTab.addClass("active");
            currentForm.find(".stepway > .step").removeClass("active").eq(currentForm.find(".tab").index(previousTab)).addClass("active");
            FocusToTop(currentForm);
        });

        currentForm.find("button.nextbtn").on("click", function () {
            var currentTab = $(this).closest(".tab");
            var nextTab = currentTab.next('.tab');
            var valid = ValidateTab(currentTab);
            if (!valid) return false;
            currentTab.removeClass("active");
            nextTab.addClass("active");
            currentForm.find(".stepway > .step").removeClass("active").eq(currentForm.find(".tab").index(nextTab)).addClass("active");
            FocusToTop(currentForm);
        });

    });
});