import $ from 'jquery';

$(function () {

    $("a[href*='collapse_']").on("click", function () {
        var url = $(this).attr('href');
        var hash = url.substring(url.indexOf('#'));
        $(hash).collapse("show").on('shown.bs.collapse', function () {
            $('html, body').animate({ scrollTop: $(hash).offset().top }, 750);
        });
    });

    if ($('.accordionblock').length) {

        if (window.location.hash) {
            $(window.location.hash).collapse("show").on('shown.bs.collapse', function () {
                $('html, body').animate({ scrollTop: $(window.location.hash).offset().top }, 750);
            });
        }

        if (document.queryCommandSupported('copy') === true) {
            $('[data-toggle="tooltip"]').tooltip();
            $("span.anchor-link").on("click", function (e) {
                e.preventDefault();
                e.stopPropagation();
                var $temp = $("<input>");
                $("body").append($temp);
                $temp.val(window.location.href.split("#")[0] + $($(this)).data("elemid")).select();
                document.execCommand("copy");
                $temp.remove();
                var elOriginalText = $(this).attr('data-original-title');
                var elDerivativeText = $(this).attr('data-derivative-title');
                $(this).attr('data-original-title', elDerivativeText).tooltip('show');
                $(this).attr('data-original-title', elOriginalText);
            });
        }

    }

});