"use strict";

// missing forEach on NodeList for IE11
if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = Array.prototype.forEach;
}

function loadImage(imageElement) {
    // If image is within a picture element, set srcset attribute for all source elements
    var parent = imageElement.parentNode;

    if (parent.tagName === 'PICTURE') {
        var sourceElements = parent.querySelectorAll('source');
        sourceElements.forEach(function (sourceElement) {
            sourceElement.srcset = sourceElement.dataset.srcset;
        });
    } // Set img src attribute


    imageElement.src = imageElement.dataset.src;
}

function initializeLazyImages() {
    // Get images that has the data-src attribute
    var lazyImages = document.querySelectorAll('img[data-src]'); // If browser doesn't support IntersectionObserver, load all images 

    if (!('IntersectionObserver' in window)) {
        lazyImages.forEach(function (imageElement) {
            return loadImage(imageElement);
        });
        return;
    } // Create observer


    var observer = new IntersectionObserver(function (entries) {
        entries.forEach(function (entry) {
            if (entry.intersectionRatio <= 0) {
                return;
            }

            var imageElement = entry.target;
            loadImage(imageElement);
            observer.unobserve(imageElement);
        });
    }, // Configuration for the observer
        {
            rootMargin: '100px' // load images if it gets within 100px

        }); // Let all lazy images be observered

    lazyImages.forEach(function (imageElement) {
        observer.observe(imageElement);
    });
}

window.onload = initializeLazyImages();