import $ from 'jquery';
import 'url-search-params-polyfill';

var pageLang = () => $('html')[0].lang;
var invoiceDateInput = $('form#step2_individual #InvoiceDateInput');
var paymentMethod = $('form#step2_individual #PaymentMethod');
var paymentProviderList = $('form#step2_individual #PaymentProviderList');
var paymentCardMethods = $("form#step2_individual #PaymentCardMethods");
var sameInvoicingCheckbox = $('#step2_company input[name="SameInvoicingAddress"]');

$(document).ready(function () {

    var urlParams = new URLSearchParams(window.location.search);
    var personType = (urlParams.get("PersonType") !== null) ? urlParams.get("PersonType") : $(".charityblock input[type=radio][name=PersonType]:checked").val();
    var amount = urlParams.get("Amount");
    var destination = urlParams.get("Destination");
    var donationType = urlParams.get("DonationType");

    if (personType) {
        $(".charityblock input[type=radio][name=PersonType][value=" + personType + "]").prop('checked', true).trigger("change");
    }

    if (donationType) {
        if (personType !== "company") {
            $('.charityblock select[name=DonationType]').val(donationType).trigger("change");
        }
    }

    if (amount) {
        var amountSelectElement = personType === "company" ? $('.charityblock select[name=PredefinedDonationAmountsCompany]') : $('.charityblock select[name=PredefinedDonationAmountsIndividual]');
        var amountInputElement = personType === "company" ? $('.charityblock input[name=DonationAmountOtherCompany]') : $('.charityblock input[name=DonationAmountOtherIndividual]');
        if (amountSelectElement.find("option[value='" + amount + "']").length) {
            amountSelectElement.val(amount).trigger("change");
        } else {
            amountSelectElement.val("").trigger("change");
            amountInputElement.val(amount).trigger("change");
        }
    }

    if (destination) {
        var destinationElement = personType === "company" ? $('.charityblock select[name=DonationDestinationCompany]') : $('.charityblock select[name=DonationDestinationIndividual]');
        destinationElement.val(destination).trigger("change");
    }
    // New requirement since 03.08.2020 , same invoicing must be checked, also checked on cshtml

    if (sameInvoicingCheckbox.length) {
        sameInvoicingCheckbox[0].checked = true;
        sameInvoicingCheckbox.prop("checked", true).trigger("change");
    }

    // If only one DonationType in hidden field
    if ($(".charityblock #step1_individual select#DonationType").length < 1) {
        var donationTypeValue = jQuery('#step1_individual input[name=DonationType]').val();
        window.UpdatePredefinedAmounts(donationTypeValue);
    }

});


//go to begin of the CharityBlock after if one-time donator comes back from bank page without finishing payment
$('.charityblock #payment-method .payment').on('click', 'button', function () {
    var position = $(this).parents('.charityblock').offset().top;
    sessionStorage.scrollCharityBlockTop = position - $(window).scrollTop();
});

if ($('.charityblock').length < 1) {
    sessionStorage.scrollCharityBlockTop = null;
}
else if (sessionStorage.scrollCharityBlockTop != "null") {
        $('html, body').animate({
            scrollTop: sessionStorage.scrollCharityBlockTop
        }, 500);
        sessionStorage.scrollCharityBlockTop = null;
}


// Step0: this one selects the correct form to show (individual vs. company)
$('input[type=radio][name=PersonType]').on("change", function () { 

    // Hide payment-method element, when PersonType is changed
    $('#payment-method').hide();

    if ($(this).val() === 'individual') {
        var donationTypeValue = jQuery('#step1_individual input[name=DonationType]').val();
        $(".charityblock #step1_individual").show();
        $(".charityblock #step1_company").hide();
        window.UpdateCharities(donationTypeValue);
        $("input#DonationAmountOtherIndividual").parent().hide();
        $('select#PredefinedDonationAmountsIndividual').trigger("change");
    }
    else if ($(this).val() === 'company') {
        $(".charityblock #step1_individual").hide();
      $(".charityblock #step1_company").show();
      window.UpdateCharities("company"); // reset to original selection
        $(".charityblock #DonationAmountOtherCompany").parent().hide();
        $('select#PredefinedDonationAmountsCompany').trigger("change");
    }

    // Reset all forms
    $("form#step1_individual, form#step1_company, #step2_individual, #step2_individual_onetime, #step2_company, #step2_company_heti")
        .removeClass('was-validated')
        .trigger("reset");
    // Hide step2's
    $("#step2_individual, #step2_individual_onetime, #step2_company, #step2_company_heti").hide();
    // Re-enable all step1 next buttons
    $('.charityblock form#step1_individual .showmore, .charityblock form#step1_company .showmore').show();

});

// Step 1: This updates the charities for individuals based on selection
window.UpdateCharities = function (type) {
    // if multiple destinations
    var multipleValues = $('select#DonationDestinationIndividual').length;

    if (multipleValues) {
        var selectElement = $('select#DonationDestinationIndividual');

        selectElement.children().remove();
        var values = type === "recurring" ? selectElement.data("recurring-values") : selectElement.data("oneoff-values");

        if (values.length) {
            $.each(values, function (__i, item) {
                selectElement.append($('<option>').text(item.Title).attr('value', item.Id));
            });
        }
        selectElement[0].selectedIndex = 0;
        selectElement.trigger("change");

    }
};

window.ValidationMarkup = function (response) {

    // Clear previous validation messages
    $(".charityblock input, .charityblock select").removeClass("is-invalid");

    $.each(response.errors, function (_i, value) {
        if (value.key) {
            var inputName = value.key.split(".").slice(-1)[0];
            if (inputName == "Amount") {
                var amountInputName = $("input#DonationAmountOtherCompany").is(":visible") ? "DonationAmountOtherCompany" : "DonationAmountOtherIndividual";
                // $('.charityblock [name="' + amountInputName + '"]').addClass("is-invalid").next().text(value.errors[0]);
                $('.charityblock [name="' + amountInputName + '"]').addClass("is-invalid");
            } else {
                $('.charityblock [name="' + inputName + '"]').addClass("is-invalid");
            }
        }
    });

    if ($('.is-invalid:first').prev("label").length) {
        $('html, body').animate({
            scrollTop: $('.is-invalid:first').prev("label").focus().offset().top
        }, 500);
    }

};

window.UpdatePredefinedAmounts = function (donationType) {
    var donatorType = $("input[name='PersonType']:checked").val();
    if (donatorType === "individual") {
        var selectElement = $('select#PredefinedDonationAmountsIndividual');
        selectElement.children('option:not(:last)').remove();
        var values = donationType === "recurring" ? selectElement.data("recurring-values").split(',') : selectElement.data("oneoff-values").split(',');
        if (values.length) {
            $.each(values.reverse(), function (_i, item) {
                selectElement.prepend($('<option>').text(item + " €").attr('value', item));
            });
        }
        selectElement[0].selectedIndex = 0;
        selectElement.trigger("change");
        $("input#DonationAmountOtherIndividual").parent().hide();
    } else if (donatorType === "company") {
        $("input#DonationAmountOtherCompany").parent().hide();
    }
};

window.parameterToInput = (param) => `<input type='hidden' name='${param.name}' value='${param.value}' />`;
window.responseToHtml = (response) => response.providers.map((provider) => `<form method='POST' action="${provider.url}">${provider.parameters.map(window.parameterToInput).join('\n')}<button><img alt='${provider.name}' src='${provider.icon}' /></button></form>`);

window.getFormData = function ($form) {
    var unindexed_array = $form.serializeArray();
    var indexed_array = {};

    $.map(unindexed_array, function (n, i) {
        indexed_array[n['name']] = n['value'];
    });

    return indexed_array;
};

// SPR-110 . Bad hack. So remove and then wait for server-side validation again
$('input').on('change', function () {
    if ($(this).hasClass('is-invalid')) $(this).removeClass('is-invalid');
});

// Step1: Individual/Private donators can select between recurring and onetime
$(".charityblock select#DonationType").on('change', function () {

    //console.log("DonationType changed");
    // Hide payment-method element, when DonationType is changed
    $('#payment-method').hide();

    var selectedValue = $(this).val();

    if (selectedValue === "onetime") {
        invoiceDateInput.hide();
        paymentMethod.hide();
        paymentProviderList.hide();
    } else if (selectedValue === "recurring") {
        invoiceDateInput.show();
        paymentMethod.show();
        paymentProviderList.show();
    }

    // Hide step2's
    $("#step2_individual, #step2_individual_onetime, #step2_company, #step2_company_heti").hide();
    // Re-enable all step1 next buttons
    $('.charityblock form#step1_individual .showmore, .charityblock form#step1_company .showmore').show();

    window.UpdatePredefinedAmounts(selectedValue);
    window.UpdateCharities(selectedValue);

});

const onlyValueDonationTypeCheck = () => {
    //console.log("DonationType changed");
    // Hide payment-method element, when DonationType is changed
    $('#payment-method').hide();

    var selectedValue = $('.charityblock input[name=DonationType]').val();

    //console.log('selectedValue', selectedValue);

    if (selectedValue === "onetime") {
        invoiceDateInput.hide();
        paymentMethod.hide();
        paymentProviderList.hide();
    } else if (selectedValue === "recurring") {
        invoiceDateInput.show();
        paymentMethod.show();
        paymentProviderList.show();
    }

    // Hide step2's
    $("#step2_individual, #step2_individual_onetime, #step2_company, #step2_company_heti").hide();
    // Re-enable all step1 next buttons
    $('.charityblock form#step1_individual .showmore, .charityblock form#step1_company .showmore').show();

}

$('select#CompanyDonationMethod').on("change", function () {
    if ($("#step2_company").is(":visible") || $("#step2_company_heti").is(":visible")) {
        $("#step2_company, #step2_company_heti").removeClass('was-validated').trigger("reset").hide();
        $('.charityblock form#step1_company .showmore').show();
    }
});

//$(".charityblock select#DonationDestinationIndividual").on('change', function () {
//     donationTypeCheck();
//});

// When charity destination changes
$(".charityblock select#DonationDestinationIndividual, .charityblock select#DonationDestinationCompany").on('change', function () {

   

  var destinationImageElem = $("body.charitylandingpage .topimage img");
  var destinationImageElemMobile = $("body.charitylandingpage .hero-section img");
    var destinationIntroductionElem = $(".charityblock .introduction:visible");
    var lang = $('html')[0].lang;

    $.get("/api/v1/charity_test/charity/" + lang + "/" + $(this).val(), function (data) {

      function changeCharityTargetImage(destinationImageElem) {
        if (destinationImageElem.length) {
          var newImage = data.image;
          var originalImage = destinationImageElem.data('src').split("?")[0];
          destinationImageElem.on("load", function () {
            $(this).fadeIn(500);
          });
          if (newImage) {
            destinationImageElem.fadeOut(500, function () {
              $.each($(this).parent().find("source"), function () {
                $(this).attr('srcset', $(this).data("srcset").replace(new RegExp(originalImage, "g"), newImage));
              });
              $(this).attr('src', $(this).data("src").replace(originalImage, newImage));
            });
          } else {
            destinationImageElem.fadeOut(500, function () {
              $.each(destinationImageElem.parent().find("source"), function () {
                $(this).attr('srcset', $(this).data("srcset"));
              });
              $(this).attr('src', $(this).data("src"));
            });
          }
        }
      }

      changeCharityTargetImage(destinationImageElem);
      changeCharityTargetImage(destinationImageElemMobile);

        if (destinationIntroductionElem.length) {
            destinationIntroductionElem.html(data.description);
        }
    });

   

});



// When payment method changes
$('#PaymentMethod input[type="radio"]').on('change', function () {
    const value = $(this)[0].value;

    switch (value) {
        case "HomeInvoice":
        case "EInvoice": {
            paymentProviderList.show();
            paymentCardMethods.hide();
            invoiceDateInput.show();
            break;
        }
        case "Card": {
            paymentCardMethods.show();
            paymentProviderList.hide();
            invoiceDateInput.hide();
            break;
        }
    }
});

// Step1 submit: Individual
$('form#step1_individual').on('submit', function (event) {

    // When there is only one value in DonationType, it is shown as disabled text field instead of select menu. 
    if ($(".charityblock select#DonationType").length < 1) {
        onlyValueDonationTypeCheck();
    }

    const formValue = window.getFormData($(this));

    event.preventDefault();
    event.stopPropagation();
    if ($(this)[0].checkValidity() === false) {
        $(this).addClass('was-validated');
    } else {
        if (formValue.DonationType === "recurring") {
            $('form#step2_individual').show();
            $('form#step2_individual_onetime').hide();
        }
        if (formValue.DonationType === "onetime") {
            $('form#step2_individual').hide();
            $('form#step2_individual_onetime').show();
        }

        $('form#step2_company').hide();
        $('form#step2_company_heti').hide();
        $(this).find(".showmore").hide();
    }
});

// Step1 submit: Company
$('form#step1_company').on('submit', function (event) {
    event.preventDefault();
    event.stopPropagation();
    if ($(this)[0].checkValidity() === false) {
        $(this).addClass('was-validated');
    } else {
        const donationMethodValue = $('select#CompanyDonationMethod')[0].value;
        if (donationMethodValue === "1") {
            $('form#step2_company_heti').hide();
            $('form#step2_individual').hide();
            $('form#step2_individual_onetime').hide();
            $('form#step2_company').show();
            $(this).find(".showmore").hide();
        } else if (donationMethodValue === "2") { // "Heti"
            $('form#step2_company').hide();
            $('form#step2_individual').hide();
            $('form#step2_individual_onetime').hide();
            $('form#step2_company_heti').show();
            $(this).find(".showmore").hide();
        }
    }
});

// Sync predefined values with hidden Amount input on individual step1 form
$("select[name='PredefinedDonationAmountsIndividual']").on('change', function () {
    //$("#individual-amount-feedback").hide();
    var value = this.value;
    var donationAmountOtherIndividual = $('input#DonationAmountOtherIndividual');
    var hiddenInput = $('form#step1_individual input[name="Amount"]');
    if (value !== "") {
        donationAmountOtherIndividual.attr("min", 0).val(0).parent().hide();
        hiddenInput.val(value);
    } else {

        var zeroValue = 0;

        var minValue = '';

        // Multiple DonationType values
        if ($(".charityblock select#DonationType").length) {
            minValue = $(".charityblock select#DonationType").val() === "recurring" ? donationAmountOtherIndividual.data("min-recurring") : donationAmountOtherIndividual.data("min-oneoff");
        } else {
            // Single DonationType value
            minValue = $(".charityblock input[name=DonationType]").val() === "recurring" ? donationAmountOtherIndividual.data("min-recurring") : donationAmountOtherIndividual.data("min-oneoff");
        }
        donationAmountOtherIndividual.attr("min", minValue).attr("data-min", minValue).val(zeroValue.toFixed(2)).parent().show(); 

        $("#individual-min-amount").text(eval(minValue).toFixed());

        hiddenInput.val(minValue); 
    }
});

const verifyDonationAmountOtherIndividual = (amount) => {

    var donationAmountOtherIndividual = $('input#DonationAmountOtherIndividual');
    var hiddenInput = $('form#step1_individual input[name="Amount"]');

    var minValue = '';

    // Multiple DonationType values
    if ($(".charityblock select#DonationType").length) {
        minValue = $(".charityblock select#DonationType").val() === "recurring" ? donationAmountOtherIndividual.data("min-recurring") : donationAmountOtherIndividual.data("min-oneoff");
    } else {
        // Single DonationType value
        minValue = $(".charityblock input[name=DonationType]").val() === "recurring" ? donationAmountOtherIndividual.data("min-recurring") : donationAmountOtherIndividual.data("min-oneoff");
    }

    var returnValue = (eval(amount) >= eval(minValue)) ? true : false;

    if (!returnValue) {
        jQuery('#individual-amount-feedback').show();
    }

    $("#individual-min-amount").text(eval(minValue).toFixed());

    if (!returnValue) {
        hiddenInput.val(minValue);
    }

    return returnValue;
    
}

// Sync manually set donation with hidden Amount input on individual step1 form
$('input#DonationAmountOtherIndividual').on('change', function () {
    $('form#step1_individual input[name="Amount"]').val(this.value);
});

// Sync predefined values with hidden Amount input on company step1 form
$("select[name='PredefinedDonationAmountsCompany']").on('change', function () {
    var value = this.value;
    var donationAmountOtherCompany = $('input#DonationAmountOtherCompany');
    var hiddenInput = $('form#step1_company input[name="Amount"]');
    if (value !== "") {
        donationAmountOtherCompany.attr("min", 0).val(0).parent().hide();
        hiddenInput.val(value);
    } else {
        var zeroValue = 0;
        var minValue = donationAmountOtherCompany.data("min");
        donationAmountOtherCompany.attr("min", minValue).val(zeroValue.toFixed(2)).parent().show();
        hiddenInput.val(minValue);
    }
});

// Sync manually set donation with hidden Amount input on company step1 form
$('input#DonationAmountOtherCompany').on('change', function () {
    $('form#step1_company input[name="Amount"]').val(this.value);
});

// End of step1

// Step2

sameInvoicingCheckbox.on('change', function () {
    if ($(this).is(':checked')) {
        $('.CompanyInvoicingAddressFields').hide();
        $(this)[0].checked = true;
    }
    else {
        $('.CompanyInvoicingAddressFields').show();
        $(this)[0].checked = false;
    }
});

const verifyDonationAmountOtherCompany = (amount) => {

    
    var donationAmountOtherCompany = $('input#DonationAmountOtherCompany');
    var hiddenInput = $('form#step1_company input[name="Amount"]');

    
    var minValue = donationAmountOtherCompany.data("min");

    var returnValue = (eval(amount) >= eval(minValue)) ? true : false;

    if (!returnValue) {
        jQuery('#DonationAmountOtherCompany ~ .invalid-feedback').show();
    }

    if (!returnValue) {
        hiddenInput.val(minValue);
    }

    return returnValue;

}

// Step2 company
$('#CompanyInvoicingType input[type="radio"]').on('change', function () {
    const value = $(this)[0].value;

    if (value === "Verkkolasku") {
        $("#verkkolasku").show();
        $("#paperilasku").hide();
    }

    if (value === "Paperilasku") {
        $("#verkkolasku").hide();
        $("#paperilasku").show();
    }

    $("#verkkolasku").find("input").val("");
    $("#paperilasku").find("input").val("");

});

// Submission should combine all values from other forms
$('#step2_company_heti').submit(function (e) {
    //console.log('#step2_company_heti');
    e.preventDefault();

    if (jQuery('#step1_company #DonationAmountOtherCompany').parent('.form-group').css("display") === "block") {
        if (!verifyDonationAmountOtherCompany(jQuery('#step1_company #DonationAmountOtherCompany').val())) {
            return false;
        }
    }

    const step1Value = window.getFormData($('#step1_company'));
    const step2Value = window.getFormData($(this));

    const formValue = {
        Language: pageLang(),
        DonationType: "onetime",
        ...step1Value,
        DonationDestination: step1Value.DonationDestinationCompany,
        ...step2Value
    };

    const requestUrl = `/api/v1/charity/company/onetime/heti/create`;

    var submitButton = $(this).find('button[type=submit]');
    var submitSpinner = $(this).find('span.submit-spinner');
    var submitText = $(this).find('span.submit-text');

    submitText.hide();
    submitSpinner.show();
    submitButton.attr("disabled", true);

    $.post(requestUrl, formValue)
        .done(function (response) {
            //console.log('response', response);
            if (response.success === true) {
                $('.charity > .payment').html(window.responseToHtml(response.data)).parent().show();
                $('.charity > .terms').html(response.data.terms);
                $('html, body').animate({
                    scrollTop: $(".charity").offset().top
                }, 500);
                submitSpinner.hide();
                submitText.show();
                submitButton.attr("disabled", false);
            } else {
                window.ValidationMarkup(response);
                submitSpinner.hide();
                submitText.show();
                submitButton.attr("disabled", false);
            }
        })
        .fail(function () {
            alert("Error while sending the request. Check back later.");
            submitSpinner.hide();
            submitText.show();
            submitButton.attr("disabled", false);
        });
});

// Step2 company with invoice (Online Invoice or Paper Invoice)
$('#step2_company').submit(function (e) {
    //console.log('#step2_company');

    // Disable button to prevent multiple submits
    let submitBtn = jQuery('.btn.btn-lg.btn-primary');
    //console.log('submitBtn', submitBtn); 
    jQuery(submitBtn).attr("disabled", true);

    e.preventDefault();


    let step0Value = window.getFormData($('#step0'));
    let step1Value = window.getFormData($('#step1_company'));
    let step2Value = window.getFormData($(this));

    let donationInvoiceType = step2Value.InvoicingType;

    let formValue = {
        Language: pageLang(),
        DonationType: "onetime", // everything is onetime
        DonationDestination: step1Value.DonationDestinationCompany,
        ...step0Value, ...step1Value, ...step2Value,
        SameInvoicingAddress: $('#SameInvoicingAddress').is(':checked'),
    };

    let requestUrl = `/api/v1/charity/company/onetime/create/${donationInvoiceType}`;

    $.post(requestUrl, formValue)
        .done(function (response) {
            //console.log('response', response);
            if (response.success === true) {
                window.location.replace(response.data);
            } else {
                window.ValidationMarkup(response);

                //console.log(response);

                if (response.errors.filter((x) => x === "Invalid amount").length) {
                    jQuery('#DonationAmountOtherCompany ~ .invalid-feedback').show();
                }

                // input field length checks
                if (jQuery('#PaperiReferenceNumber').val().length > 36) {
                    jQuery('#PaperiReferenceNumber ~ .invalid-feedback').show();
                }
                if (jQuery('#ElectricReferenceNumber').val().length > 36) {
                    jQuery('#ElectricReferenceNumber ~ .invalid-feedback').show();
                }

                // Enable button if validation was not successful
                jQuery(submitBtn).attr("disabled", false);
                // console.log('submitBtn enabled');

            }
        })
        .fail(function () {
            alert("Error while sending the request. Check back later.");
        });
});

$('#step2_individual_onetime').submit(function (e) {

    e.preventDefault();

    if (jQuery('#step1_individual #DonationAmountOtherIndividual').parent('.form-group').css("display") === "block") {
        if (!verifyDonationAmountOtherIndividual(jQuery('#step1_individual #DonationAmountOtherIndividual').val())) {
            return false;
        }
    }

    
    let step0Value = window.getFormData($('#step0'));
    let step1Value = window.getFormData($('#step1_individual'));
    let step2Value = window.getFormData($(this));

    let requestUrl = `/api/v1/charity/individual/onetime/create`;

    let formValue = {
        Language: pageLang(),
        PersonType: step0Value.PersonType,
        ...step1Value,
        DonationDestination: step1Value.DonationDestinationIndividual,
        ...step2Value
    };

    var submitButton = $(this).find('button[type=submit]');
    var submitSpinner = $(this).find('span.submit-spinner');
    var submitText = $(this).find('span.submit-text');

    submitText.hide();
    submitSpinner.show();
    submitButton.attr("disabled", true);

    $.post(requestUrl, formValue)
        .done(function (response) {
            if (response.success === true) {
                $('.charity > .payment').html(window.responseToHtml(response.data)).parent().show();
                $('.charity > .terms').html(response.data.terms);
                $('html, body').animate({
                    scrollTop: $(".charity").offset().top
                }, 500);
                submitSpinner.hide();
                submitText.show();
                submitButton.attr("disabled", false);
            } else if (response.success === false) {
                window.ValidationMarkup(response);
                submitSpinner.hide();
                submitText.show();
                submitButton.attr("disabled", false);
            }
        })
        .fail(function () {
            alert("Error while sending the request. Check back later.");
            submitSpinner.hide();
            submitText.show();
            submitButton.attr("disabled", false);
        });

});

// Step2 individual
$('#step2_individual').submit(function (e) {

    e.preventDefault();


    // Additional check for other amount value, check didn't work when allowed sum was first typed on #step1_individual and later changed to disallowed value
    if (jQuery('#step1_individual #DonationAmountOtherIndividual').parent('.form-group').css("display") === "block") {
        if (!verifyDonationAmountOtherIndividual(jQuery('#step1_individual #DonationAmountOtherIndividual').val())) {
            return false;
        }
    }

    // Phone number field check
    if (jQuery('#step2_individual #RecurringPhone').val() === '') {
        jQuery('#step2_individual #RecurringPhone ~ .invalid-feedback').show();
        return false;
    }

    //Individual monthly donation bank selection check
    //console.log(jQuery('#step2_individual #PaymentProvider').val());
    if (jQuery('#step2_individual #PaymentProvider').val() === '') {
        console.log("select bank");
        jQuery('#step2_individual #PaymentProvider ~ .invalid-feedback').show();
        return false;
    }

    let step0Value = window.getFormData($('#step0'));
    let step1Value = window.getFormData($('#step1_individual'));
    let step2Value = window.getFormData($(this));

    let donationPerson = step0Value.PersonType;
    let donationType = step1Value.DonationType;

    let requestUrl = `/api/v1/charity/${donationPerson}/${donationType}/create`;

    let formValue = {
        Language: pageLang(),
        PersonType: step0Value.PersonType,
        ...step1Value,
        DonationDestination: step1Value.DonationDestinationIndividual,
        ...step2Value
    };

    var submitButton = $(this).find('button[type=submit]');
    var submitSpinner = $(this).find('span.submit-spinner');
    var submitText = $(this).find('span.submit-text');

    submitText.hide();
    submitSpinner.show();
    submitButton.attr("disabled", true);

    $.post(requestUrl, formValue)
        .done(function (response) {
            if (response.success === true) {
                if (donationType === "onetime") {
                    $('.charity > .payment').html(window.responseToHtml(response.data)).parent().show();
                    $('.charity > .terms').html(response.data.terms);
                    submitSpinner.hide();
                    submitText.show();
                    submitButton.attr("disabled", false);
                } else {
                    window.location.replace(response.data);
                }
            } else if (response.success === false) {
                window.ValidationMarkup(response);
                // Assuming we have an incorrect RecurringZip value as api/payment/externalpayment api validating zip code returns response.success = false
                jQuery('#RecurringZip ~ .invalid-feedback').show();

                submitSpinner.hide();
                submitText.show();
                submitButton.attr("disabled", false);
            }
        })
        .fail(function () {
            alert("Error while sending the request. Check back later.");
            submitSpinner.hide();
            submitText.show();
            submitButton.attr("disabled", false);
        });

});