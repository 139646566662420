import $ from 'jquery';

$(window).on('load resize', function () {
    if ($(".navbar").length) {
        $(document.body).css('margin-top', $(".navbar").height() + "px");
    }
});

$(document).ready(function () {

    $('#navbarNavMarkup').on('show.bs.collapse', function () {
        $("body").addClass("overflow-hidden");
        /*$(".navbar button.navbar-toggler > .description").text("sulje");*/
        $(".navbar button.navbar-toggler > .description .menu-text").hide();
        $(".navbar button.navbar-toggler > .description .close-text").show();
    });

    $('#navbarNavMarkup').on('hide.bs.collapse', function () {
        $("body").removeClass("overflow-hidden");
        /*$(".navbar button.navbar-toggler > .description").text("valikko");*/
        $(".navbar button.navbar-toggler > .description .close-text").hide();
        $(".navbar button.navbar-toggler > .description .menu-text").show();
    });

    $('#navbarNavMarkup').on('hidden.bs.collapse', function () {
        $(document.body).css('margin-top', $(".navbar").height() + "px");
    });

});

var prevScrollpos = window.pageYOffset;
window.onscroll = function () {
    var currentScrollPos = window.pageYOffset;
    var navBar = document.getElementsByClassName("navbar")[0];
    navBar.style.top = prevScrollpos > currentScrollPos || currentScrollPos < navBar.offsetHeight ? "0" : "-" + navBar.offsetHeight + "px";
    prevScrollpos = currentScrollPos;
};

