(function () {
    var youtube = document.querySelectorAll(".youtubeblock > .embed-responsive");
    for (var i = 0; i < youtube.length; i++) {
        youtube[i].addEventListener("click", function () {

            var iframe = document.createElement("iframe");
            iframe.setAttribute("frameborder", "0");
            iframe.setAttribute("allowfullscreen", "");
            iframe.setAttribute("class", "embed-responsive-item");
            iframe.setAttribute("src", "https://www.youtube.com/embed/" + this.dataset.embed + "?rel=0&showinfo=0&autoplay=1&mute=1");
            this.innerHTML = "";
            this.appendChild(iframe);

        });
    }
})();