/**
 * --------------------------------------------------------------------------
 * Styles (Imported on scss file)
 * --------------------------------------------------------------------------
 */

import './Static/scss/site.scss';

/**
 * --------------------------------------------------------------------------
 * jQuery
 * --------------------------------------------------------------------------
 */

import $ from 'jquery';

window.jQuery = $;
window.$ = $;
window.jquery = $;

/**
 * --------------------------------------------------------------------------
 * Bootstrap
 * --------------------------------------------------------------------------
 */

import 'popper.js';
// import 'bootstrap';
// Alternatively, you may import plugins individually as needed:
// import 'bootstrap/js/dist/util';
// import 'bootstrap/js/dist/alert';
import 'bootstrap/js/dist/collapse'; // Required by Accordion Block
import 'bootstrap/js/dist/tooltip'; // Required by: Accordion Block
import 'bootstrap/js/dist/dropdown'; // Required by: Main navigation
import 'bootstrap/js/dist/button'; // Required by: Main navigation
import 'bootstrap/js/dist/modal'; // Required by: Cookie Consent

/**
 * --------------------------------------------------------------------------
 * Custom
 * --------------------------------------------------------------------------
 */

/* Global */
import './Static/js/navbar';
import './Static/js/lazyload';
import './Static/js/table-responsive';
import './Static/js/ofi';
import './Static/js/BSFrontEndValidator';
import './Static/js/search';

/* Blocks */
import './Features/Blocks/HeroBlock/HeroBlock';
import './Features/Blocks/YouTubeBlock/YouTubeBlock';
import './Features/Blocks/AccordionBlock/AccordionBlock';
import './Features/Blocks/CharityBlock/CharityBlock';
import './Features/Blocks/SegmentPollBlock/SegmentPollBlock';